import React from "react"
import styled from "styled-components"
// import Filter from "./Filter"
import Title from "./Title"
import Projects from "./Projects"
import { MdOutlineKeyboardArrowRight } from "react-icons/md"
import { graphql, Link, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allContentfulProjects(
      sort: { fields: updatedAt, order: DESC }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        id
        title
        type
        url
        content {
          tags
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
      }
    }
  }
`

const Portfolio = () => {
  const {
    allContentfulProjects: { nodes: projects },
  } = useStaticQuery(query)

  // const [items, setItems] = useState(projects)
  // console.log(projects[0])

  // const filterItems = filter => {
  //   if (filter === "all") {
  //     setItems(projects)
  //     return
  //   }

  //   const newItems = items.filter(item => {
  //     const tags = item.content.tags
  //     return tags.includes(filter)
  //   })
  //   setItems(newItems)
  // }

  // console.log(items)

  return (
    <Container className="section" id="portfolio">
      <div className="section-center">
        <Title subtitle="featured" title="Portfolio" />
        {/* <Filter projects={projects} /> */}
        <Projects projects={projects} />
        <div className="btn-container">
          <Link to="/portfolio" className="btn projects-btn">
            <span>see more</span>
            <MdOutlineKeyboardArrowRight />
          </Link>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;

  .btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .projects-btn {
    display: flex;
    align-items: center;
    padding: none;
    color: #53565a;
    font-size: 1.5rem;
  }
  span {
    transition: 0.25s;
  }
  svg {
    transition: 0.25s;
    font-size: 2.5rem;
    padding-top: 0.25rem;
  }
  .projects-btn:hover,
  svg:hover {
    color: #e5f4f4;
  }
  .projects-btn:hover > svg {
    transition: 0.5s;
    transform: translateX(0.5rem);
  }
`

export default Portfolio
