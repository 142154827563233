import React from "react"
import Hero from "../components/Hero"
import Portfolio from "../components/Products"
import About from "../components/About"
import Contact from "../components/Contact"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import styled from "styled-components"
import "../assets/css/index.css"

const Home = () => {
  return (
    <Main id="home">
      <Layout>
        <SEO title="Home" />
        <Hero />
        <Portfolio />
        <About />
        <Contact />
      </Layout>
    </Main>
  )
}

const Main = styled.main`
  width: 100%;
  position: relative;
`

export default Home
