import React from "react"
import styled from "styled-components"

const Title = ({ subtitle, title }) => {
  return (
    <Container className="section-title">
      <h2 className="title">
        {subtitle} <strong>{title}</strong>
      </h2>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  .title {
    font-weight: 200;
  }
`

export default Title
