import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const About = () => {
  return (
    <Container className="section" id="about">
      <div className="section-center">
        {/* About Image */}
        <div className="img-container">
          <StaticImage
            src="../assets/images/HeroImage3.jpg"
            alt="about me"
            className="about-img"
            placeholder="dominantColor"
          />
        </div>
        {/* About Content */}
        <div className="about-content">
          <div className="about-title">
            <h2 className="title">
              about <strong>me</strong>
            </h2>
          </div>
          <p className="about-text big">
            I am a multidisciplinary developer and designer based in Southern
            California. I build visual identities, UI/UX, and functionality for
            websites.
          </p>
          <p className="about-text small">
            Freelance Web Developer and Sales Manager. 2 years of experience as
            a Freelance Developer. 6 years of experience in client facing roles.
            9 years of experience learning, studying, and utilizing Korean.
          </p>
          {/* About Button */}
          <div className="btn-container">
            <Link to="/contact">
              <button className="btn about-btn">Contact</button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;

  .section-center {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
  }
  .about-title {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 2rem;
    .title {
      font-weight: 200;
    }
  }
  .about-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .img-container {
    width: 100%;
    margin-bottom: 4rem;
  }
  .about-img {
    width: 100%;
  }
  .about-text {
    width: 85%;
  }
  .big {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .small {
    margin-bottom: 3rem;
  }
  .btn-container {
    width: 30%;
  }
  .about-btn {
    width: 100%;
    color: #181818;
    background-color: #17b8bd;
    transition: 0.125s;

    &:hover {
      transition: 0.125s;
      background-color: #e5f4f4;
    }
  }

  @media (min-width: 800px) {
    .section-center {
      flex-direction: row;
    }
    .img-container {
      padding-right: 1rem;
      margin-bottom: 0;
    }
    .about-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 3rem;
    }
    .about-title {
      margin-bottom: 1rem;
    }
    .about-text {
      width: 100%;
    }
    .small {
      margin-bottom: 1.25rem;
    }
  }
`

export default About
