import React from "react"
import styled from "styled-components"
import { MdOutlineKeyboardArrowRight } from "react-icons/md"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <Container>
      <div className="content">
        <h1 className="title">
          Hello, I'm <span className="name">Joey</span>.
        </h1>
        <h1 className="subtitle">I'm a web developer.</h1>
        <div className="btn-container">
          <Link to="/portfolio" className="btn hero-btn">
            <span>see work</span>
            <MdOutlineKeyboardArrowRight />
          </Link>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(315deg, #181818 0%, #052526 100%);
  height: 95vh;
  overflow: hidden;
  width: 100%;

  .content {
    text-align: center;
  }
  .title {
    text-transform: none;
  }
  .name {
    color: #17b8bd;
  }
  .subtitle {
    font-weight: 200;
    text-transform: none;
  }
  .btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .hero-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #53565a;
    font-size: 1rem;
  }
  span {
    transition: 0.5s;
  }
  svg {
    transition: 0.5s;
    font-size: 1.5rem;
  }
  .hero-btn:hover,
  svg:hover {
    color: #e5f4f4;
  }
  .hero-btn:hover > svg {
    transition: 0.5s;
    transform: translateX(0.5rem);
  }

  @media (min-width: 800px) {
    height: 95vh;
    overflow: hidden;
    width: 100%;

    .hero-btn {
      width: 25%;
    }
  }
`

export default Hero
